import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NotFound = () => {
  const location = useLocation();
  
  useEffect(() => {
    if (!location.state?.from) {
      window.history.replaceState(
        { from: window.location.pathname },
        '',
        '/404'
      );
    }
  }, [location.state]);


  return (
    <div className="min-h-[50vh] w-full flex items-center justify-center">
      <div className="text-center space-y-6 px-4">
        <div className="space-y-2">
          <h1 className="text-4xl font-bold tracking-tighter">404</h1>
          <h2 className="text-2xl font-semibold text-gray-700">Page not found</h2>
          <p className="text-gray-500 max-w-lg mx-auto">
            Sorry, we couldn't find the page you're looking for. Please check the URL or return home.
          </p>
          {location.state?.from && location.state.from !== '/404' && (
            <p className="text-sm text-gray-400">
              Path not found: {location.state.from}
            </p>
          )}
        </div>
        <div className="flex justify-center space-x-4">
            404

        </div>
      </div>
    </div>
  );
};

export default NotFound;
